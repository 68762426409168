import {TRDAStatus} from "@/constants/request/TRDAConstants";
import {CustomRequestStatus} from "@/constants/request/CustomRequestConstants";

export enum RequestType {
    CBSR = 'CBSR',
    W9CR = 'W9CR',
    FRSO = 'FRSO',
    ITRQ = 'ITRQ',
    RER = 'RER',
    RIDC = 'RIDC',
    IAAR = 'IAAR',
    CDRQ = 'CDRQ',
    TRDA = 'TRDA',
    CUSTOM = 'CUSTOM'
}

export type RequestStatus = RCCRRequestStatus | CCSRequestStatus | RCLRequestStatus | NCLRequestStatus | RCIRequestStatus | NCIRequestStatus | RCERequestStatus | CBSRStatus
     | W9CRStatus | FRSOStatus | ITRQStatus | RERStatus | RIDCStatus | IAARStatus | CDRQStatus | TRDAStatus | CustomRequestStatus;

export enum CBSRStatus {
    CREATED = 'CREATED',
    DOCUMENTS_BEING_UPLOADED = 'DOCUMENTS_BEING_UPLOADED',
    DOCUMENTS_UPLOADED = 'DOCUMENTS_UPLOADED',
    COMPLETED = 'COMPLETED'
}

export enum RCCRRequestStatus {
    CREATED = 'CREATED',
    DOCUMENTS_UPLOADED = 'DOCUMENTS_UPLOADED',
    CALL_SCHEDULED = 'CALL_SCHEDULED',
    WAITING_FOR_LINKED_REQUESTS = 'WAITING_FOR_LINKED_REQUESTS',
    COMPLETED = 'COMPLETED'
}

export enum CCDRRequestStatus {
    CREATED = 'CREATED',
    DOCUMENTS_UPLOADED = 'DOCUMENTS_UPLOADED',
    COMPLETED = 'COMPLETED'
}

export enum RCCRDocumentType {
    SS_4 = 'SS_4',
    CERTIFICATE_OF_INCORPORATION = 'CERTIFICATE_OF_INCORPORATION',
    FILING_RECEIPT = 'FILING_RECEIPT',
    OFFICER_ID = 'OFFICER_ID',
    OFFICER_SSN = 'OFFICER_SSN'
}

export enum CCSRequestStatus { // Change company status
    CREATED = 'CREATED',
    COMPLETED = 'COMPLETED'
}

export enum RCLRequestStatus { // Request company license
    CREATED = 'CREATED',
    COMPLETED = 'COMPLETED'
}

export enum NCLRequestStatus { // Create company license
    CREATED = 'CREATED',
    COMPLETED = 'COMPLETED'
}

export enum RCIRequestStatus { // Request company insurance
    CREATED = 'CREATED',
    COMPLETED = 'COMPLETED'
}

export enum NCIRequestStatus { // Create company insurance
    CREATED = 'CREATED',
    COMPLETED = 'COMPLETED'
}

export enum RCERequestStatus { // Request company employments
    CREATED = 'CREATED',
    COMPLETED = 'COMPLETED'
}

export enum W9CRStatus {
    CREATED = 'CREATED',
    PENDING_FOR_CUSTOMER = 'PENDING_FOR_CUSTOMER',
    ACTION_REQUIRED = 'ACTION_REQUIRED',
    COMPLETED = 'COMPLETED'
}

export enum FRSOStatus {
    SENT = "SENT",
    OPENED = "OPENED",
    ACH = "ACH",
    PAYMENT_LINK = "PAYMENT_LINK",
    REJECTED = "REJECTED",
    COMPLETED = "COMPLETED",
}

export enum ITRQStatus {
    SENT = 'SENT',
    OPENED = 'OPENED',
    BEING_FILLED_OUT = 'BEING_FILLED_OUT',
    PENDING_FOR_CONFIRMATION = 'PENDING_FOR_CONFIRMATION',
    PENDING_FOR_SIGNED_CONFIRMATION = 'PENDING_FOR_SIGNED_CONFIRMATION',
    CONFIRMED = 'CONFIRMED',
    BEING_PREPARED = 'BEING_PREPARED',
    PREPARED = 'PREPARED',
    COMPLETED = 'COMPLETED'
}

export enum RERStatus {
    CREATED_BY_SE = 'CREATED_BY_SE',
    CREATED_BY_CUSTOMER = 'CREATED_BY_CUSTOMER',
    SENT_TO_EMPLOYEES = 'SENT_TO_EMPLOYEES',
    ACTION_REQUIRED = 'ACTION_REQUIRED',
    COMPLETED = 'COMPLETED'
}

export enum RIDCStatus {
    SENT = "SENT",
    OPENED = "OPENED",
    FILLED_BY_CUSTOMER = "FILLED_BY_CUSTOMER",
    COMPLETED = "COMPLETED"
}

export enum IAARStatus {
    CREATED = "CREATED",
    COMPLETED = "COMPLETED"
}

export enum CDRQStatus {
    SENT = "SENT",
    OPENED = "OPENED",
    BEING_FILLED_OUT = "BEING_FILLED_OUT",
    PENDING_FOR_CONFIRMATION = 'PENDING_FOR_CONFIRMATION',
    PENDING_FOR_SIGNING_CONFIRMATION = 'PENDING_FOR_SIGNING_CONFIRMATION',
    CONFIRMED = 'CONFIRMED',
    PENDING_LATER_INFO = 'PENDING_LATER_INFO',
    COMPLETED = 'COMPLETED',
}

